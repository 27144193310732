import React from 'react';
// import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import sectionTotalStyle from "../jss/sections/sectionTotalStyle";
import { useRateAndTvl,useRateAndReward} from '../redux/hooks';
import { formatLocaleNumber } from 'features/helpers/utils';
const useStyles = makeStyles(sectionTotalStyle);

function all(list){
    let val = 0;
    list.forEach(item => {
        val += item[1]*item[2]/1e36
    });
    return val.toFixed(4);
}
export default props => {
    const { t } = useTranslation();
    let rateAndTvl = useRateAndTvl();
    let rateAndReward = useRateAndReward();
    const classes = useStyles();
    return <div className={classes.box + ' total-total'}>
        <div className={classes.col}>
            <div className={classes.colspan}><img className={classes.icon} src={require(`../../../images/coin.svg`)} />{t('Total-lock-in')}</div>
            <div className={classes.num}><span>$</span>{all(rateAndTvl)}</div>
        </div>
        <div className={classes.col}>
        <div className={classes.colspan}><img className={classes.icon} src={require(`../../../images/money-107.svg`)} />{t('User-income-of-the-day')}</div>
            <div className={classes.num}><span>$</span>{all(rateAndReward)}</div>
        </div>
    </div>
}