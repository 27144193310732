
const sectionTotalStyle = theme => ({
  box:{
    color: "#fff",
    border: "1px solid #3f4a68",
    boxShadow: "0px 4px 8px 0px rgb(0 0 0 / 6%)",
    borderRadius: "12px",
    backgroundColor: "#323951",
    display:"flex",
    padding:"30px 16px",
    fontWeight: "bold",
    fontSize:"24px",
    marginBottom:"20px"
  },
  col:{
    width:"50%",
    display:"flex",
    alignItems:"center",
    flexDirection:"column"
  },
  colspan:{
    fontSize:"20px",
    marginBottom:"12px",
  },
  icon:{
    marginRight:"10px",
    position:"relative",
    top:"-2px"
  },
  num:{
    "font-family": "DINPro-Medium",
    "& span":{
      fontSize:"18px",
      marginRight:"5px",
      position:"relative",
      top:"-2px",
    }
  }
});

export default sectionTotalStyle;
