import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from './redux/actions';
import Button from '@material-ui/core/Button';

let displayed = [];

const Notifier = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const notifications = useSelector(store => store.common.notifications || []);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    React.useEffect(() => {
        notifications.forEach(({ key, message, options = {}, dismissed = false, hash }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }
            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;
            if(!message){
                // yangyong......
                return;
            }
            // display snackbar using notistack
            enqueueSnackbar(<span className="enqueueSnackbar-tips">{message}</span>, {
                key,
                ...options,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                  },
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
                autoHideDuration: 3000,//-todo....
                action: hash?()=><Button onClick={() => window.open(`https://scan.hecochain.com/tx/${hash}`, "_blank")}><span style={{color:"white"}}>{t("Vault-View")}</span></Button>:null
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, t]);

    return null;
};

export default Notifier;