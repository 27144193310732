import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles({
    showDetail:{
        alignItems:'center',
        justifyContent:'space-around',
        width:'100%',
        height: '56px',
        background: '#272E42',
        borderRadius: '12px',
        fontWeight: '600',
        fontSize: '18px',
        color: '#FFFFFF',
        lineHeight: '24px',
        '& fieldset':{
            borderWidth:'1px !important',
            borderColor:"#272E42 !important"
        },
        '&:hover fieldset':{
            borderColor:"#01A2FE !important"
        },
        '&.Mui-focused fieldset':{
            borderColor:"#01A2FE !important"
        }
    },
});

export default function CustomOutlinedInput(props) {
    const commonStyle = useStyles();
    const commonClasses = {
        root: commonStyle.showDetail
    };
    const {
        classes
    } = props;
    return (
        <OutlinedInput 
            {...props}
            classes={Object.assign({},commonClasses,classes)}
            />
    )
}

CustomOutlinedInput.defaultProps = {
    variant:"outlined",
    fullWidth:true,
}