/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BigNumber from "bignumber.js";
import { byDecimals, calculateReallyNum } from 'features/helpers/bignumber';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion'
// import classNames from "classnames";
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions'
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
// import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
// core components
import Button from "components/CustomButtons/Button.js";
import Avatar from '@material-ui/core/Avatar';
// sections for this section
import { useSnackbar } from 'notistack';
//  hooks
import { useConnectWallet } from '../../home/redux/hooks';
import { useSetAndGetTotals, useFetchBalances, useFetchPoolBalances, useFetchApproval, useFetchDeposit, useFetchWithdraw, useEarned, useApy, useDeposited } from '../redux/hooks';
import CustomSlider from 'components/CustomSlider/CustomSlider';

import sectionPoolsStyle from "../jss/sections/sectionPoolsStyle";
import SectionTitle from "./SectionTitle";
import SectionQuestion from "./SectionQuestion";
import SectionTotal from "./SectionTotal";
import SectionCountDown from "./SectionCountDown";
// import { reflect } from 'async';
import { inputLimitPass, inputFinalVal, isEmpty, formatLocaleNumber } from 'features/helpers/utils';
import { useRateAndTvl } from '../redux/hooks';
const useStyles = makeStyles(sectionPoolsStyle);

export default function SectionPools() {
  const { t, i18n } = useTranslation();
  const { web3, address, networkId } = useConnectWallet();
  let { pools, fetchPoolBalances } = useFetchPoolBalances();
  // 设置池子的总额度
  let { getPoolTotal } = useSetAndGetTotals();
  const { tokens, fetchBalances } = useFetchBalances();
  const [openedCardList, setOpenCardList] = useState([]);
  const classes = useStyles();

  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { fetchDeposit, fetchDepositEth, fetchDepositPending } = useFetchDeposit();
  const { fetchWithdraw, fetchWithdrawEth, fetchWithdrawPending, fetchWithdrawIncomePending } = useFetchWithdraw();
  const rateAndTvl = useRateAndTvl();
  const [depositedBalance, setDepositedBalance] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState({});

  const x5TokenAddress = pools.find(({ id }) => id === 'MDX').tokenAddress;
  const x5 = rateAndTvl.find(item => item[0].toLowerCase() === x5TokenAddress.toLowerCase());
  const MDXROTE = x5 ? x5[1] : 0;


  const { enqueueSnackbar } = useSnackbar();

  const changeDetailInputValue = (type, index, total, tokenDecimals, event) => {
    total = Number(total)
    // console.log('changeDetailInputValue---->',total)

    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    if (isNaN(Number(value))) {
      value = ''
    }
    let sliderNum = 0;
    let inputVal = Number(value.replace(',', ''));
    if (value) {
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }
    switch (type) {
      case 'depositedBalance':
        setDepositedBalance({
          ...depositedBalance,
          [index]: inputFinalVal(value, total, tokenDecimals),
          [`slider-${index}`]: sliderNum,
        });
        break;
      case 'withdrawAmount':
        setWithdrawAmount({
          ...withdrawAmount,
          [index]: inputFinalVal(value, total, tokenDecimals),
          [`slider-${index}`]: sliderNum,
        });
        break;
      default:
        break;
    }
  }

  const handleDepositedBalance = (index, total, event, sliderNum) => {
    console.log('handleDepositedBalance', total);
    let calculateReallyNumVal = calculateReallyNum(total, sliderNum);
    console.log('handleDepositedBalance', calculateReallyNumVal)
    // let precision = calculateReallyNumVal.split('.')[1]
    // if (precision) {
    //   calculateReallyNumVal -= Number(`0.${'1'.padStart(precision.length,'0')}`)
    // }
    setDepositedBalance({
      ...depositedBalance,
      [index]: sliderNum == 0 ? '0' : calculateReallyNumVal,
      [`slider-${index}`]: sliderNum == 0 ? 0 : sliderNum,
    });
  }

  const handleWithdrawAmount = (index, total, event, sliderNum) => {
    total = Number(String(total).replace(',', ''))
    console.log('handleWithdrawAmount', total)
    let calculateReallyNumVal = calculateReallyNum(total, sliderNum);
    console.log('handleWithdrawAmount', calculateReallyNumVal)
    // return
    // let precision = calculateReallyNumVal.split('.')[1]
    // if (precision) {
    //   calculateReallyNumVal -= Number(`0.${'1'.padStart(precision.length,'0')}`)
    // }
    setWithdrawAmount({
      ...withdrawAmount,
      [index]: sliderNum == 0 ? '0' : calculateReallyNumVal,
      [`slider-${index}`]: sliderNum == 0 ? 0 : sliderNum,
    });
    // console.log('handleWithdrawAmount',withdrawAmount)
  };

  const onApproval = (pool, index, event) => {
    event.stopPropagation();
    fetchApproval({
      address,
      web3,
      tokenAddress: pool.tokenAddress,
      contractAddress: pool.earnContractAddress,
      index
    }).then(
      () => enqueueSnackbar(`${t("Approval-success")}`, {
        variant: 'success', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 2000
      })
    ).catch(
      error => enqueueSnackbar(`${t("Approval-error")}: ${error}`, {
        variant: 'error', anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 2000
      })
    )
  }

  const onDeposit = (pool, index, isAll, balanceSingle, event) => {
    event.stopPropagation();
    if (isAll) {
      setDepositedBalance({
        ...depositedBalance,
        [index]: String(forMat(balanceSingle, 8)),
        [`slider-${index}`]: 100,
      })
    }
    let amountValue = depositedBalance[index] ? depositedBalance[index].replace(',', '') : depositedBalance[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    if (depositedBalance[`slider-${index}`] === 100) {
      isAll = true;
    }

    if (!pool.tokenAddress) {
      // 如果是eth
      fetchDepositEth({
        address,
        web3,
        amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
        contractAddress: pool.earnContractAddress,
        contractToken: pool.tokenAddress,
        index
      }).then(
        () => enqueueSnackbar(`${t("Deposit-success")}:`, {
          variant: 'success', anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          autoHideDuration: 2000
        })
      ).catch(
        error => enqueueSnackbar(`${t('Deposit-error')}: ${error}`, {
          variant: 'error', anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          autoHideDuration: 2000
        })
      )
    } else {
      fetchDeposit({
        address,
        web3,
        isAll,
        amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
        contractAddress: pool.earnContractAddress,
        contractToken: pool.tokenAddress,
        index
      }).then(
        () => {
          setDepositedBalance({ ...depositedBalance, [index]: 0, [`slider-${index}`]: 0 })
          enqueueSnackbar(`${t("Deposit-success")}`, {
            variant: 'success', anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            autoHideDuration: 2000
          })
        }
      ).catch(
        error => {
          enqueueSnackbar(`${t('Deposit-error')}: ${error}`, {
            variant: 'error', anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            autoHideDuration: 2000
          })
        }
      )
    }
  }

  const onWithdraw = (pool, index, isAll, singleDepositedBalance, isWithdrawIncome, event) => {

    event.stopPropagation();
    if (isAll) {
      setWithdrawAmount({
        ...withdrawAmount,
        [index]: String(forMat(singleDepositedBalance, 8)),
        [`slider-${index}`]: 100,
      })
    }
    let amountValue = withdrawAmount[index] ? withdrawAmount[index].replace(',', '') : withdrawAmount[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    if (withdrawAmount[`slider-${index}`] === 100) {
      isAll = true;
    }

    if (!pool.tokenAddress) {// 如果是eth
      fetchWithdrawEth({
        address,
        web3,
        isAll,
        amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.itokenDecimals)).toString(10),
        contractAddress: pool.earnContractAddress,
        contractToken: pool.tokenAddress,
        index
      }).then(
        () => {
          setWithdrawAmount({ ...withdrawAmount, [index]: 0, [`slider-${index}`]: 0 })
          enqueueSnackbar(`${t('Withdraw-success')}`, {
            variant: 'success', anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            autoHideDuration: 2000
          })
        }
      ).catch(
        error => enqueueSnackbar(`${t('Withdraw-error')}: ${error}`, {
          variant: 'error', anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
      )
    } else {
      fetchWithdraw({
        address,
        web3,
        isAll,
        amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.itokenDecimals)).toString(10),
        contractAddress: pool.earnContractAddress,
        contractToken: pool.tokenAddress,
        index,
        isWithdrawIncome
      }).then(
        () => {
          setWithdrawAmount({ ...withdrawAmount, [index]: 0, [`slider-${index}`]: 0 })
          enqueueSnackbar(`${t('Withdraw-success')}`, {
            variant: 'success', anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            autoHideDuration: 2000
          })
        }
      ).catch(
        error => {
          // setWithdrawAmount({ ...withdrawAmount, [index]: 0, [`slider-${index}`]: 0 })
          enqueueSnackbar(`${t('Withdraw-error')}:${error}`, {
            variant: 'error', anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            autoHideDuration: 2000
          })
        }
      )
    }

  }

  const openCard = id => {
    return setOpenCardList(
      openedCardList => {
        if (openedCardList.includes(id)) {
          return openedCardList.filter(item => item !== id)
        } else {
          return [...openedCardList, id]
        }
      }
    )
  }
  useEffect(() => {
    if (address && web3) {
      fetchBalances({ address, web3, tokens });
      fetchPoolBalances({ address, web3, pools });
      // 获取所有池子的项目总额并设置
      getPoolTotal()
      const id = setInterval(() => {
        fetchBalances({ address, web3, tokens });
        fetchPoolBalances({ address, web3, pools });
        // 获取所有池子的项目总额并设置
        getPoolTotal()
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, web3, fetchBalances, fetchPoolBalances]);

  const isMoreDepostLimit = (value, depostLimit) => {
    if (isEmpty(value) || depostLimit == 0 || value < depostLimit) {
      return false
    }
    return true;
  }
  const matchTvl = (address) => {
    const matched = rateAndTvl.find(tvl => tvl[0].toLowerCase() === address.toLowerCase());
    if (!matched) {
      return (0).toFixed(2)
    }
    return (matched[2] / Number(`1e${matched[3]}`)).toFixed(2)
  }
  const matchRote = (address) => {
    const matched = rateAndTvl.find(tvl => tvl[0].toLowerCase() === address.toLowerCase()) || [];
    return matched[1]
  }
  const forMat = (number, precision = 2) => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber().toFixed(precision)
  }

  return (
    <>
      {/* 说明文字 */}
      <SectionTitle />
      {/* 倒计时 */}
      <SectionCountDown endDate="2021-03-05 14:00:00" date={{ year: 2021, month: 3, day: 5, h: 14, m: 0, s: 0 }} />
      {/* 总锁仓量总收益 */}
      <SectionTotal />
      <Grid container style={{ paddingTop: '4px' }}>
        {/* <Grid item xs={12}>
        <div className={classes.mainTitle}>{t('Vault-Main-Title')}</div>
        <h3 className={classes.secondTitle}>{t('Vault-Second-Title')}</h3>
      </Grid> */}

        {Boolean(networkId === Number(process.env.NETWORK_ID)) && pools.map((pool, index) => {
          // 余额
          const balanceSingle = byDecimals(tokens[pool.token].tokenBalance, pool.tokenDecimals);
          const singleDepositedBalance = byDecimals(tokens[pool.earnedToken].tokenBalance, pool.itokenDecimals);
          // 已存入
          let deposite = useDeposited(pool.earnContractAddress, pool.tokenAddress);
          deposite = byDecimals(deposite, pool.tokenDecimals).toNumber();
          // 已获益
          const earned = useEarned(pool.earnContractAddress, pool.tokenAddress);
          // 回报率
          const rateMdx = matchRote(pool.tokenAddress) / MDXROTE || pool.rateMdx;
          let apy = useApy(pool.earnContractAddress, pool.tokenAddress, pool, rateMdx) / (100 * rateMdx);
          const depositedApy = forMat(apy);

          return (
            <Grid item xs={12} container key={index} style={{ marginBottom: "24px" }} spacing={0}>
              <div style={{ width: "100%" }}>
                <Accordion
                  expanded={Boolean(openedCardList.includes(index))}
                  className={classes.accordion}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    className={classes.details + ' coin-wrap'}
                    style={{ justifyContent: "space-between" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      // alert(pool.disabled)
                      if (pool.disabled) return
                      openCard(index)
                    }}
                  >
                    <Grid container className="coin-col-wrap" alignItems="center" justify="space-around" spacing={4}>
                      {/* 币种名称+ICON */}
                      <Grid item className="coin-icon-wrap">
                        <Grid container alignItems="center" spacing={2}>
                          <Grid xs={5} md={5} item>
                            <Avatar
                              alt={pool.token}
                              src={require(`../../../images/icon2/${pool.token}.svg`)}
                            />
                          </Grid>
                          <Grid xs={6} md={6} item >
                            <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom>
                              {pool.token}
                              <SectionQuestion value={<span className="SectionQuestion-custom-tips">
                                <time>{pool.openDate}</time>
                                <span>{t('Vault-View-9')}<strong>{pool.openAmount}</strong>{t('Vault-View-10')}</span>
                              </span>} label={pool.type == 'NEW' ? "NEW" : t('Vault-View-11')} type={pool.type ? 1 : 0} />
                            </Typography>
                            <Typography className={classes.iconContainerSubTitle} variant="body2">
                              {t('Vault-Tips-3')}{pool.token}{t('Vault-Tips-4')}
                              <SectionQuestion value={t('Vault-Tips1')} />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* 各字段区域 */}
                      <Grid item md={8} xs={10} className="coin-params-wrap">
                        <Grid item container justify="space-between">
                          {/* 未提收益 */}
                          <Hidden smDown>
                            <Grid item xs={3} container justify='flex-start' alignItems="center">
                              <Grid item>
                                <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>{String((earned / 1e18).toFixed(5)).slice(0, -1)}<SectionQuestion value={t('Vault-TipsEarned')} /></Typography>
                                <Typography className={classes.iconContainerSubTitle} variant="body2">{t('Vault-ListEarned')} (MDX)</Typography>
                              </Grid>
                            </Grid>
                          </Hidden>
                          {/* 年化收益率 */}
                          {/* <Hidden mdDown> */}
                          <Grid item xs={3} md={3} container justify='flex-start' alignItems="center">
                            <Grid item>
                              {/* <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap> {earned}%</Typography> */}
                              <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap style={{ color: '#FFAF67' }}>{isNaN(depositedApy) ? '0.00' : depositedApy}%</Typography>
                              <Typography className={classes.iconContainerSubTitle} variant="body2">{t('Vault-ListAPY')}</Typography>
                            </Grid>
                          </Grid>
                          {/* </Hidden> */}
                          {/* 总锁仓量 */}
                          <Hidden smDown>
                            <Grid item xs={3} container justify='center' alignItems="center">
                              <Grid item style={{ width: "200px" }}>
                                <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>{matchTvl(pool.tokenAddress)}</Typography>
                                <Typography className={classes.iconContainerSubTitle} variant="body2">
                                  {t('Lock-volume')} ({pool.token})
                                </Typography></Grid>
                            </Grid>
                          </Hidden>
                          {/*  */}
                          {/* <Hidden smDown>
                            <Grid item xs={3} container justify='center' alignItems="center">
                              <Grid item style={{ width: "200px" }}>
                                <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>{forMat(balanceSingle)} {pool.token}</Typography>
                                <Typography className={classes.iconContainerSubTitle} variant="body2">
                                  {t('Vault-Balance')}
                                </Typography></Grid>
                            </Grid>
                          </Hidden> */}

                          {/* 进度条 */}
                          <Hidden mdDown>
                            <Grid item xs={3} container justify='flex-start' alignItems="center">
                              <Grid item style={{ width: "200px" }}>
                                <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>
                                  <span className="progress-total">
                                    <span className="progress-total-innner" style={{ width: `${(matchTvl(pool.tokenAddress) / pool.total).toFixed(2) * 100}%` }}></span>
                                  </span>
                                  {(matchTvl(pool.tokenAddress) / pool.total).toFixed(2) * 100 > 100 ? 100 : (pool.total == 0 ? 0 : String((matchTvl(pool.tokenAddress) / pool.total * 100)).split('.')[0])}%
                                </Typography>
                                <Typography className={classes.iconContainerSubTitle} variant="body2">
                                  <span style={{ color: 'white', "fontFamily": "DINPro-Regular" }}>{t('Remaining-investment')}:{(pool.total - matchTvl(pool.tokenAddress)).toFixed(2) <= 0 ? (0).toFixed(2) : (pool.total - matchTvl(pool.tokenAddress)).toFixed(2)}{pool.token}</span>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Hidden>
                          {/* 已存入 */}
                          {/* <Hidden mdDown>
                            <Grid item xs={3} container justify='flex-start' alignItems="center">
                              <Grid item style={{ width: "200px" }}>
                                <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>{deposite} {pool.token}</Typography>
                                <Typography className={classes.iconContainerSubTitle} variant="body2">{t('Vault-Deposited')}</Typography>
                              </Grid>
                            </Grid>
                          </Hidden> */}
                        </Grid>
                      </Grid>
                      {/* 打开关闭按钮 */}
                      <Grid item>
                        <Grid item container justify="flex-start" alignItems="center" spacing={2}>
                          <Grid item>
                            <IconButton
                              className={classes.iconContainerPrimary + `${pool.disabled ? ' IconButton-disabled' : ''}`}
                              onClick={(event) => {
                                event.stopPropagation();
                                if (pool.disabled) return
                                openCard(index);
                              }}
                              disabled={pool.disabled}
                            >
                              {
                                openedCardList.includes(index) ? <i className={"yfiiicon yfii-arrow-up"} /> : <i className={"yfiiicon yfii-arrow-down"} />
                              }
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* h5下显示的参数布局 */}
                    <ul className="coin-params-h5-wrap">
                      <li><span>{t('Vault-ListEarned')}({pool.earnedToken})<SectionQuestion value={t('Vault-TipsEarned')} /></span><strong>{String((earned / 1e18).toFixed(5)).slice(0, -1)} </strong></li>
                      <li><span>{t('Vault-ListAPY')}</span><strong className="depositedApy">{isNaN(depositedApy) ? '0.00' : depositedApy}%</strong></li>
                      <li><span>{t('Lock-volume')}({pool.token})</span><strong>{matchTvl(pool.tokenAddress)}</strong></li>
                      <li><span>
                        {t('Vault-SYDeposited')}({pool.token})
                        <SectionQuestion value={t('Vault-Tips2')} />
                      </span>
                        <strong>{(pool.total - matchTvl(pool.tokenAddress)).toFixed(2) <= 0 ? (0).toFixed(2) : (pool.total - matchTvl(pool.tokenAddress)).toFixed(2)}</strong></li>
                      <li style={{ "fontFamily": "DINPro-Regular", fontWeight: "bold" }}>
                        <span className="progress-total">
                          <span className="progress-total-innner" style={{ width: `${(matchTvl(pool.tokenAddress) / pool.total).toFixed(2) * 100}%` }}></span>
                        </span>
                        {(matchTvl(pool.tokenAddress) / pool.total).toFixed(2) * 100 > 100 ? 100 : String((matchTvl(pool.tokenAddress) / pool.total * 100)).split('.')[0]}%
                      </li>
                    </ul>
                  </AccordionSummary>
                  <AccordionDetails style={{ justifyContent: "space-between" }}>
                    <Grid container style={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
                      <Grid item xs={12} sm={6} className={classes.sliderDetailContainer}>
                        <div className={classes.showDetailRight}>
                          <span className="vault-sy-deposited">{t('Vault-SYDeposited')}:{(pool.total - matchTvl(pool.tokenAddress)).toFixed(2) <= 0 ? (0).toFixed(2) : (pool.total - matchTvl(pool.tokenAddress)).toFixed(2)} {pool.token}<SectionQuestion value={t('Vault-Tips2')} /></span>
                          <span>
                            {t('Vault-Balance')}:{String(balanceSingle.toFormat(5)).slice(0, -1)} {pool.token}
                          </span>
                        </div>
                        <FormControl fullWidth variant="outlined">
                          <CustomOutlinedInput
                            value={depositedBalance[index] != undefined ? depositedBalance[index] : '0'}
                            onChange={changeDetailInputValue.bind(this, 'depositedBalance', index, balanceSingle.toNumber(), pool.tokenDecimals)}
                          />
                        </FormControl>
                        <CustomSlider
                          classes={{
                            root: classes.depositedBalanceSliderRoot,
                            markLabel: classes.depositedBalanceSliderMarkLabel,
                          }}
                          aria-labelledby="continuous-slider"
                          value={depositedBalance['slider-' + index] ? depositedBalance['slider-' + index] : 0}
                          onChange={handleDepositedBalance.bind(this, index, balanceSingle.toNumber())}
                        />
                        <div>
                          {
                            pool.allowance === 0 ? (
                              <div className={classes.showDetailButtonCon}>
                                <Button
                                  style={{
                                    width: '180px',
                                    margin: '12px 5px',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    backgroundImage: 'linear-gradient(90deg, #01A3FE 0%, #006EFF 100%)',
                                    color: '#FFF',
                                    borderRadius: "30px",
                                    boxShadow: '0 2px 2px 0 rgba(53, 56, 72, 0.14), 0 3px 1px -2px rgba(53, 56, 72, 0.2), 0 1px 5px 0 rgba(53, 56, 72, 0.12)',
                                    fontWeight: "bold"
                                  }}
                                  color="primary"
                                  onClick={onApproval.bind(this, pool, index)}
                                  disabled={fetchApprovalPending[index]}
                                >
                                  {fetchApprovalPending[index] ? `${t('Vault-ApproveING')}` : `${t('Vault-ApproveButton')}`}
                                </Button>
                              </div>
                            ) : (
                                <div className={classes.showDetailButtonCon}>
                                  <Button
                                    style={{
                                      width: '180px',
                                      height: "48px",
                                      margin: '12px 5px',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      backgroundImage: 'linear-gradient(90deg, #01A3FE 0%, #006EFF 100%)',
                                      color: '#FFF',
                                      boxShadow: '0 2px 2px 0 rgba(53, 56, 72, 0.14), 0 3px 1px -2px rgba(53, 56, 72, 0.2), 0 1px 5px 0 rgba(53, 56, 72, 0.12)'
                                    }}
                                    color="primary"
                                    round
                                    onFocus={(event) => event.stopPropagation()}
                                    disabled={
                                      !Number(String((depositedBalance[index])).replace(',', '')) || fetchDepositPending[index] || isMoreDepostLimit(new BigNumber(depositedBalance[index]).toNumber(), pool.depostLimit)
                                    }
                                    onClick={onDeposit.bind(this, pool, index, false, balanceSingle)}
                                  >
                                    {fetchDepositPending[index] ? t('Vault-DepositING') : t('Vault-DepositButton')}
                                  </Button>
                                  {Boolean(pool.tokenAddress) && <Button
                                    style={{
                                      width: '180px',
                                      margin: '12px 5px',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      backgroundImage: "linear-gradient(90deg, #01A3FE 0%, #006EFF 100%)",
                                      backgroundColor: '#323951',
                                      color: '#FFF',
                                      boxShadow: '0 2px 2px 0 rgba(53, 56, 72, 0.14), 0 3px 1px -2px rgba(53, 56, 72, 0.2), 0 1px 5px 0 rgba(53, 56, 72, 0.12)'
                                    }}
                                    round
                                    onFocus={(event) => event.stopPropagation()}
                                    disabled={
                                      !balanceSingle.toNumber() || fetchDepositPending[index] || (isMoreDepostLimit(balanceSingle.toNumber(), pool.depostLimit))
                                    }
                                    onClick={onDeposit.bind(this, pool, index, true, balanceSingle)}
                                  >{fetchDepositPending[index] ? t('Vault-DepositING') : t('Vault-DepositButtonAll')}
                                  </Button>}
                                </div>
                              )
                          }
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} className={classes.sliderDetailContainer}>
                        <div className={classes.showDetailRight}>
                          <span>{t('Vault-WithdrawCan')}:{String(deposite.toFixed(5)).slice(0, -1)} {pool.token}</span>
                          <span>
                            {t('Vault-Income')}:{String((earned / 1e18).toFixed(5)).slice(0, -1)} {pool.earnedToken}
                          </span>
                        </div>
                        <FormControl fullWidth variant="outlined">
                          <CustomOutlinedInput
                            value={withdrawAmount[index] != undefined ? withdrawAmount[index] : '0'}
                            onChange={changeDetailInputValue.bind(this, 'withdrawAmount', index, deposite, pool.itokenDecimals)}
                          />
                        </FormControl>
                        <CustomSlider
                          classes={{
                            root: classes.depositedBalanceSliderRoot,
                            markLabel: classes.depositedBalanceSliderMarkLabel,
                          }}
                          aria-labelledby="continuous-slider"
                          value={withdrawAmount['slider-' + index] ? withdrawAmount['slider-' + index] : 0}
                          onChange={handleWithdrawAmount.bind(this, index, deposite)}
                        />
                        <div className={classes.showDetailButtonCon}>
                          <Button
                            style={{
                              width: '180px',
                              margin: '12px 5px',
                              fontSize: '14px',
                              fontWeight: 'bold',
                              backgroundColor: "#5D6D89",
                              color: '#FFF',
                              boxShadow: '0 2px 2px 0 rgba(53, 56, 72, 0.14), 0 3px 1px -2px rgba(53, 56, 72, 0.2), 0 1px 5px 0 rgba(53, 56, 72, 0.12)'
                            }}
                            round
                            type="button"
                            color="primary"
                            disabled={!Number(String(withdrawAmount[index]).replace(',', '')) || fetchWithdrawPending[index]}
                            onClick={onWithdraw.bind(this, pool, index, false, deposite, false)}
                          >
                            {fetchWithdrawPending[index] ? `${t('Vault-WithdrawING')}` : `${t('Vault-WithdrawButton')}`}
                          </Button>
                          <Button
                            style={{
                              width: '180px',
                              margin: '12px 5px',
                              fontSize: '14px',
                              fontWeight: 'bold',
                              backgroundColor: "#5D6D89",
                              boxSizing: "border-box",
                              color: '#FFF',
                              boxShadow: '0 2px 2px 0 rgba(53, 56, 72, 0.14), 0 3px 1px -2px rgba(53, 56, 72, 0.2), 0 1px 5px 0 rgba(53, 56, 72, 0.12)',
                            }}
                            disabled={fetchWithdrawIncomePending[index] || !Number(forMat(earned / 1e18, 8))}
                            round
                            type="button"
                            color="primary"
                            onClick={onWithdraw.bind(this, pool, index, false, deposite, true)}
                          >
                            {fetchWithdrawIncomePending[index] ? `${t('Vault-WithdrawING')}` : `${t('Vault-WithdrawIncome')}`}
                          </Button>
                        </div>
                      </Grid>

                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          )
        })}

      </Grid>
    </>
  )
}
