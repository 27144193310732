//  fetchBalances
export const SWAP_FETCH_BALANCES_BEGIN = 'SWAP_FETCH_BALANCES_BEGIN';
export const SWAP_FETCH_BALANCES_SUCCESS = 'SWAP_FETCH_BALANCES_SUCCESS';
export const SWAP_FETCH_BALANCES_FAILURE = 'SWAP_FETCH_BALANCES_FAILURE';

//  checkApproval
export const SWAP_CHECK_APPROVAL_BEGIN = 'SWAP_CHECK_APPROVAL_BEGIN';
export const SWAP_CHECK_APPROVAL_SUCCESS = 'SWAP_CHECK_APPROVAL_SUCCESS';
export const SWAP_CHECK_APPROVAL_FAILURE = 'SWAP_CHECK_APPROVAL_FAILURE';

//  fetchApproval
export const SWAP_FETCH_APPROVAL_BEGIN = 'SWAP_FETCH_APPROVAL_BEGIN';
export const SWAP_FETCH_APPROVAL_SUCCESS = 'SWAP_FETCH_APPROVAL_SUCCESS';
export const SWAP_FETCH_APPROVAL_FAILURE = 'SWAP_FETCH_APPROVAL_FAILURE';

//  fetchZapOrSwap
export const SWAP_FETCH_ZAP_OR_SWAP_BEGIN = 'SWAP_FETCH_ZAP_OR_SWAP_BEGIN';
export const SWAP_FETCH_ZAP_OR_SWAP_SUCCESS = 'SWAP_FETCH_ZAP_OR_SWAP_SUCCESS';
export const SWAP_FETCH_ZAP_OR_SWAP_FAILURE = 'SWAP_FETCH_ZAP_OR_SWAP_FAILURE';
