import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// 多语言
import { useTranslation } from 'react-i18next';
// css
import '../../assets/jss/Tips.css'

export default function Tips() {
    const { t } = useTranslation();
    const [ tipsIsRender, setTipsIsRender ] = useState(true);
    return (
        tipsIsRender
        &&
        <div className="version-tips">
            <img src={require('../../images/gonggao.svg')} alt=""/>
            <span className="tip-box">
                <span className="nav-tip">{t("nav-tip")}</span>
                <a href="https://www.coinwind.com/" className="nav-switch">{t('nav-switch')}V2</a>
            </span>
            <img className="tips-close" src={require('../../images/guanbi.svg')} alt="" onClick={() => {setTipsIsRender(false)}}/>
        </div>
    );
}