// import BigNumber from "bignumber.js";
// id: '池子id',
// name: '池子名字',  
// token: '池子代币',
// tokenDescription: '代币描述',
// tokenAddress: '代币ERC20地址',
// tokenDecimals: '存入精度'
// itokenDecimals: '提取精度'
// depostLimit: '存入最大数量限制' 0时不限制
// earnedToken: '奖励代币',
// earnedTokenAddress: '奖励代币ERC20地址',
// earnContractAddress: '池子合约地址',
// price ： 挖的代币的价格！
// path price: 
// -todo....

// 线上
export const earnContractAddress = "0xAf4cBbDdd17D1fdAD956663a54eE8960De231348"
// 测试的合约地址
// export const earnContractAddress = "0xdb8D9d989571A4B752F540F4EfA55e01BAed2975"
// 测试策略地址
// export const earnContractAddress = "0xfFbCA0299B6540b400fe6343ca86df85b6184Ac1"
// 新增代币地址
// export const earnContractAddress = "0x0298c2b32eae4da002a15f36fdf7615bea3da047"
export const pools = [
  {
    // 标识新的，表示开放额度
    // type: 'NEW',
    // 开放额度
    openAmount: "40,000,000",
    // 开放时间
    openDate: "2021.02.26 14:00:00",
    // 开放了吗
    // disabled: true,
    id: 'HUSD',
    name: 'HUSD',
    token: 'HUSD',
    tokenDescription: 'HUDS',
    tokenAddress: '0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047',
    tokenDecimals: 8,
    itokenDecimals: 8,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "58.06",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 0.169315,
    total: 40000000
  },
  {
    // 标识新的，表示开放额度
    // type: '二期',
    // 开放额度
    openAmount: "24,000,000",
    // 开放时间
    openDate: "2021.02.26 14:00:00",
    // 开放了吗
    disabled: false,
    id: 'USDT',
    name: 'USDT',
    token: 'USDT',
    tokenDescription: 'USDT',
    tokenAddress: '0xa71edc38d189767582c38a3145b5873052c3e47a',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "58.06",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 0.169315,
    total: 150000000
  },
  {
    // 标识新的，表示开放额度
    type: '三期',
    // 开放额度
    openAmount: "6,000,000",
    // 开放时间
    openDate: "2021.03.05 14:00:00(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'MDX',
    name: 'MDX',
    token: 'MDX',
    tokenDescription: 'MDX',
    tokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "120.23",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 1,
    total: 10000000
  },
  {
    // 标识新的，表示开放额度
    type: '三期',
    // 开放额度
    openAmount: "2,000,000",
    // 开放时间
    openDate: "2021.03.05 14:00:00(GTM+8)",
    // 开放了吗
    disabled: false,
    id: 'WHT',
    name: 'WHT',
    token: 'WHT',
    tokenDescription: 'WHT',
    tokenAddress: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "56.51",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 4.04,
    total: 4100000
  },

  {
    // 标识新的，表示开放额度
    // type: '二期',
    // 开放额度
    openAmount: "200",
    // 开放时间
    openDate: "2021.02.26 14:00:00",
    // 开放了吗
    disabled: false,
    id: 'HBTC',
    name: 'HBTC',
    token: 'HBTC',
    tokenDescription: 'HBTC',
    tokenAddress: '0x66a79d23e58475d2738179ca52cd0b41d73f0bea',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "35.95",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 10614.3,
    total: 1200
  },
  {
    // 标识新的，表示开放额度
    // type: '二期',
    // 开放额度
    openAmount: "6,000",
    // 开放时间
    openDate: "2021.02.26 14:00:00",
    // 开放了吗
    disabled: false,
    id: 'ETH',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'ETH',
    tokenAddress: '0x64FF637fB478863B7468bc97D30a5bF3A428a1fD',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit: 0,
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'MDX',
    earnedTokenAddress: '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
    earnContractAddress,
    defaultApy: "30.03",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
    rateMdx: 391,
    total: 31000
  }
  // {
  //   id: 'usdc',
  //   name: 'USDC',  
  //   token: 'USDC',
  //   tokenDescription: 'USDC',
  //   tokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  //   tokenDecimals: 6,
  //   itokenDecimals: 6,
  //   depostLimit:0,
  //   tokenDescriptionUrl: '',
  //   tokenDescriptionUrl2: '',
  //   earnedToken: 'iUSDC',
  //   earnedTokenAddress: '0x23B4dB3a435517fd5f2661a9c5a16f78311201c1',
  //   earnContractAddress: '0x23B4dB3a435517fd5f2661a9c5a16f78311201c1',
  //   defaultApy: "39.54",
  //   pricePerFullShare: 1,
  //   pastPricePerFullShare: 1
  // },
  // {
  //   id: 'dai',
  //   name: 'DAI',  
  //   token: 'DAI',
  //   tokenDescription: 'DAI',
  //   tokenAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  //   tokenDecimals: 18,
  //   itokenDecimals: 18,
  //   depostLimit:0,
  //   tokenDescriptionUrl: '',
  //   tokenDescriptionUrl2: 'https://docs.dfi.money/#/zh-cn/buy-tokens?id=_2-dai%e5%85%91%e6%8d%a2',
  //   earnedToken: 'iDAI',
  //   earnedTokenAddress: '0x1e0DC67aEa5aA74718822590294230162B5f2064',
  //   earnContractAddress: '0x1e0DC67aEa5aA74718822590294230162B5f2064',
  //   defaultApy: "86.3",
  //   pricePerFullShare: 1,
  //   pastPricePerFullShare: 1
  // },
  // {
  //   id: 'husd',
  //   name: 'HUSD',  
  //   token: 'HUSD',
  //   tokenDescription: 'HUSD',
  //   tokenAddress: '0xdF574c24545E5FfEcb9a659c229253D4111d87e1',
  //   tokenDecimals: 8,
  //   itokenDecimals: 18,
  //   depostLimit:50000,
  //   tokenDescriptionUrl: '',
  //   tokenDescriptionUrl2: '',
  //   earnedToken: 'iHUSD',
  //   earnedTokenAddress: '0xED434A25612B8d64E3257Fff5f96B33031729fDF',
  //   earnContractAddress: '0xED434A25612B8d64E3257Fff5f96B33031729fDF',
  //   defaultApy: "42.63",
  //   pricePerFullShare: 1,
  //   pastPricePerFullShare: 1
  // },
  // {
  //   id: 'busd',
  //   name: 'BUSD',  
  //   token: 'BUSD',
  //   tokenDescription: 'BUSD',
  //   tokenAddress: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  //   tokenDecimals: 18,
  //   itokenDecimals: 18,
  //   depostLimit:0,
  //   tokenDescriptionUrl: '',
  //   tokenDescriptionUrl2: '',
  //   earnedToken: 'iBUSD',
  //   earnedTokenAddress: '0xc46d2fC00554f1f874F37e6e3E828A0AdFEFfbcB',
  //   earnContractAddress: '0xc46d2fC00554f1f874F37e6e3E828A0AdFEFfbcB',
  //   defaultApy: "42.63",
  //   pricePerFullShare: 1,
  //   pastPricePerFullShare: 1
  // },
  // {
  //   id: 'hbtc',
  //   name: 'HBTC',  
  //   token: 'HBTC',
  //   tokenDescription: 'HBTC',
  //   tokenAddress: '0x0316eb71485b0ab14103307bf65a021042c6d380',
  //   tokenDecimals: 18,
  //   itokenDecimals: 18,
  //   depostLimit:0,
  //   tokenDescriptionUrl: '',
  //   tokenDescriptionUrl2: '',
  //   earnedToken: 'iHBTC',
  //   earnedTokenAddress: '0x26AEdD2205FF8a87AEF2eC9691d77Ce3f40CE6E9',
  //   earnContractAddress: '0x26AEdD2205FF8a87AEF2eC9691d77Ce3f40CE6E9',
  //   defaultApy: "86.3",
  //   pricePerFullShare: 1,
  //   pastPricePerFullShare: 1
  // },
  // {
  //   id: 'tusd',
  //   name: 'TUSD',  
  //   token: 'TUSD',
  //   tokenDescription: 'TUSD',
  //   tokenAddress: '0x0000000000085d4780B73119b644AE5ecd22b376',
  //   tokenDecimals: 18,
  //   itokenDecimals: 18,
  //   depostLimit:0,
  //   tokenDescriptionUrl: '',
  //   tokenDescriptionUrl2: '',
  //   earnedToken: 'iTUSD',
  //   earnedTokenAddress: '0x4243f5C8683089b65a9F588B1AE578d5D84bFBC9',
  //   earnContractAddress: '0x4243f5C8683089b65a9F588B1AE578d5D84bFBC9',
  //   defaultApy: "42.63",
  //   pricePerFullShare: 1,
  //   pastPricePerFullShare: 1
  // },
  // {
  //   id: 'ycrv',
  //   name: 'yCRV',  
  //   token: 'yCRV',
  //   tokenDescription: 'yCRV',
  //   tokenAddress: '0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8',
  //   tokenDecimals: 18,
  //   itokenDecimals: 18,
  //   depostLimit:0,
  //   tokenDescriptionUrl: 'https://docs.dfi.money/#/using-crv?id=how-to-get-ycrv',
  //   tokenDescriptionUrl2: 'https://docs.dfi.money/#/zh-cn/buy-tokens?id=_5-ycrv%e5%85%91%e6%8d%a2',
  //   earnedToken: 'iYCRV',
  //   earnedTokenAddress: '0x3E3db9cc5b540d2794DB3861BE5A4887cF77E48B',
  //   earnContractAddress: '0x3E3db9cc5b540d2794DB3861BE5A4887cF77E48B',
  //   defaultApy: "42.63",
  //   pricePerFullShare: 1,
  //   pastPricePerFullShare: 1
  // },
]