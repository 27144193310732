/* eslint-disable */
import React, { useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import Footer from "components/Footer/Footer.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Popover from '@material-ui/core/Popover';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/components/footerLinksStyle.js";
import classNames from "classnames";
import {
    grayColor,
    roseColor,
    primaryColor,
    secondaryColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    blackColor,
    whiteColor,
    twitterColor,
    facebookColor,
    googleColor,
    linkedinColor,
    pinterestColor,
    youtubeColor,
    tumblrColor,
    behanceColor,
    dribbbleColor,
    redditColor,
    instagramColor,
    hexToRgb
} from "assets/jss/material-kit-pro-react.js";
const useStyles = makeStyles(styles);

const footerLinkArr = [
    { content: 'Audit-Report', href: 'https://www.coinwind.pro/Flyhub-Audit-Report-022121.pdf'},
    { content: 'HUOBI-Wallet-Tutorial', href: 'https://www.defibox.com/defiStrategy/184?utm_source=1651601'},
    { content: 'TP-Wallet-Tutorial', href: 'https://www.yuque.com/docs/share/4a0a6ed5-5039-4954-8c50-d7691a5dda8a'},
    { content: 'CodeBank-Wallet-Tutorial', href: 'https://www.yuque.com/docs/share/8eb5fbca-d0df-41d5-a169-87904ac3b0a8' },
    { content: 'WEB-Tutorial', href: 'https://www.yuque.com/docs/share/9b99e05f-3eec-4acb-acf9-32f2a740efa3' },
];

export default function FooterLinks(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const color = props.color;
    const badgeClasses = classNames({
        [classes.container]: true,
        [classes.fixed]: props.fixed,
    });

    //留着控制底部联系方式的颜色
    const iconGroundStyle = {
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: "1.25rem",
        borderRadius: "1.25rem",
        color: 'white',
        backgroundColor: primaryColor[0],
    }

    const iconColorStyle = {
        fontSize: "24px",
        color: "#fff"
    }

    return (
        <div className={badgeClasses}>
            <List className={classes.list}>
                <ListItem className={classes.listItem}>
                    <Button
                        color={color}
                        className={
                            classes.navLink + " " + classes.socialIconsButton
                        }
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >
                        <i
                            style={iconColorStyle}
                            className={
                                "yfiiicon yfii-wechat"
                            }
                        />
                    </Button>
                    <Popover
                        id="mouse-over-popover"
                        className={classes.popover}
                        classes={{
                            paper: classes.paper,
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        // anchorOrigin={{
                        //     vertical: 'top',
                        //     horizontal: 'left',
                        // }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <Avatar variant="square" alt="CoinWind" src={require(`../../images/Wechat.jpeg`)} style={{ width: "80px", height: "80px" }} />
                    </Popover>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Button
                        color={color}
                        className={
                            classes.navLink + " " + classes.socialIconsButton
                        }
                        href="https://twitter.com/coinwind_com"
                        target="_blank"
                    >
                        <i
                            style={iconColorStyle}
                            className={
                                "yfiiicon yfii-twitter"
                            }
                        />
                    </Button>
                </ListItem>

                {/* <ListItem className={classes.listItem}>
                    <Button
                        color={color}
                        className={
                            classes.navLink + " " + classes.socialIconsButton
                        }
                        href="#"
                    >
                        <i
                            style={iconColorStyle}
                            className={
                                "yfiiicon yfii-telegram"
                            }
                        />
                    </Button>
                </ListItem> */}
                {/* <ListItem className={classes.listItem}>
                    <Button
                        color={color}
                        className={
                            classes.navLink + " " + classes.socialIconsButton
                        }
                        href="#"
                    >
                        <i
                            style={iconColorStyle}
                            className={
                                "yfiiicon yfii-discord"
                            }
                        />
                    </Button>
                </ListItem> */}
            </List>
            <GridContainer className={classes.linkList + ' footer-custom-link'} justify='center' align='center' >
                {
                    footerLinkArr.map((item) => {
                        return (
                            <GridItem className={classes.linkItem} md={2} xs={6} key={item.content} >
                                <a className={classes.extraContent} target="_blank" href={item.href}>{t(item.content)}</a>
                            </GridItem >
                        )
                    })
                }
            </GridContainer>
        </div>
    )
}

FooterLinks.defaultProps = {
    color: "transparent",
    fixed: false
};

FooterLinks.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    fixed: PropTypes.bool,
};
