import React, { useState, useEffect, memo } from 'react';
import '../jss/sections/SectionCountDown.css'
import { useTranslation } from 'react-i18next';
import Button from "components/CustomButtons/Button.js";
//时分秒倒计时方法
function countTime(endDateTime) {
    //获取当前时间
    var date = new Date();
    var now = date.getTime();
    //设置截止时间
    var endDate = new Date(endDateTime.year, endDateTime.month - 1, endDateTime.day, endDateTime.h, endDateTime.m, endDateTime.s);
    var end = endDate.getTime();
    //时间差
    var differTime = end - now;
    //定义变量,h,m,s保存倒计时的时间
    var h, m, s;
    if (differTime >= 0) {
        h = Math.floor(differTime / 1000 / 60 / 60);
        m = Math.floor(differTime / 1000 / 60 % 60);
        s = Math.floor(differTime / 1000 % 60);
        h = h < 10 ? ("0" + h) : h;
        m = m < 10 ? ("0" + m) : m;
        s = s < 10 ? ("0" + s) : s;
    } else {
        h = '00';
        m = '00';
        s = '00';
    }
    return [h, m, s]
}
export default memo(props => {
    const { t } = useTranslation();
    const { endDate, date } = props;
    const [time, setTime] = useState(['0', '0', '0']);
    const [endTimeSuccess, setEndTimeSuccess] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    function setCountDown() {
        const timer = countTime(date);
        // console.log(timer.join(''))
        if (!parseInt(timer.join(''))) {
            setEndTimeSuccess(true);
            // return
        }
        setTime(timer)
    }
    useEffect(() => {
        setCountDown();
        const timer = window.setInterval(setCountDown, 1000)
        return () => window.clearInterval(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <div className={`CountDown-time-wrap${endTimeSuccess ? ' CountDown-time-wrap-ing' : ''}`}>
        {
            openDialog ? <div className="hot-open-dialog">
                <div className="hot-open-dialog-inner">
                    <h4>{t("Vault-View-1")}：<span>{endDate}(GTM+8)</span></h4>
                    <table>
                         <tr><td>{t("Vault-View-2")}</td><td>{t("Vault-View-3")}</td><td>{t("Vault-View-4")}</td></tr> 
                        {/* <tr><td>USDT</td><td>125,600,600</td><td>24,000,000</td></tr>
                        <tr><td>HUSD</td><td>0</td><td>40,000,000</td></tr>  */}
                        <tr><td>MDX</td><td>4,000,000</td><td>6,000,000</td></tr>
                        <tr><td>WHT</td><td>2,100,000</td><td>2,000,000</td></tr>
                        {/* <tr><td>BTC</td><td>1,000</td><td>200</td></tr>
                        <tr><td>ETH</td><td>25,000</td><td>6,000</td></tr> */}
                    </table>
                    <div className="hot-open-dialog-inner-btn">
                        <Button
                            style={{
                                width: '180px',
                                height: "48px",
                                margin: '12px auto',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                backgroundImage: 'linear-gradient(90deg, #01A3FE 0%, #006EFF 100%)',
                                color: '#FFF',
                                boxShadow: '0 2px 2px 0 rgba(53, 56, 72, 0.14), 0 3px 1px -2px rgba(53, 56, 72, 0.2), 0 1px 5px 0 rgba(53, 56, 72, 0.12)'
                            }}
                            color="primary"
                            round
                            onClick={() => setOpenDialog(false)}
                        >
                            {t("Vault-View-5")}
                        </Button>
                    </div>
                </div>
            </div> : null
        }

        {
            endTimeSuccess ? <div className="hoting">{t("Vault-View-6")}</div> : <div><ul className="CountDown-time">
                <li time="HOURS">
                    <div before={String(time[0]).split('')[0]} after={String(time[0]).split('')[1]}></div>
                </li>:
            <li time="MINUTES">
                    <div before={String(time[1]).split('')[0]} after={String(time[1]).split('')[1]}></div>
                </li>:
            <li time="SECONDS">
                    <div before={String(time[2]).split('')[0]} after={String(time[2]).split('')[1]}></div>
                </li>
            </ul>
                <p className="open-timer">{t("Vault-View-7")}：{endDate} (GTM+8)<span onClick={() => setOpenDialog(true)}>{t("Vault-View-8")}&gt;&gt;</span></p></div>
        }

    </div>
})