import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';
export default props => {
    const {value,label,type=0} = props;
    const [show,setShow] = useState(false)
    const { t } = useTranslation();
    function toggleShow(e){
        e.stopPropagation()
        setShow(!show)
    }
    return <span className={`question-coin-pop${type===1?' question-coin-pop-type':''}`} label={label} onClick={e=>e.stopPropagation()} onMouseEnter={toggleShow} onMouseLeave={toggleShow}>
        <span className={'question-coin-tips' + (show?' question-coin-tips--show':'')}>{value}</span>
    </span>
}