import { useState, useCallback, useEffect } from 'react';
import { defiBankAbi, erc20ABI, definBankControlAbi, StrategyAbi } from 'features/configure/abi';
import BigNumber from "bignumber.js";
import { useSnackbar } from 'notistack';
import { useConnectWallet } from '../../home/redux/hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    VAULT_FETCH_POOL_BALANCES_SUCCESS
} from './constants';
import { pools } from "../../configure/pools";
import async, { reject } from 'async';
export { useFetchBalances } from './fetchBalances';
export { useFetchPoolBalances } from './fetchPoolBalances';
export { useFetchApproval } from './fetchApproval';
export { useFetchDeposit } from './fetchDeposit';
export { useFetchWithdraw } from './fetchWithdraw';
export { useFetchContractApy } from './fetchContractApy';
// 池子管理
export function usePoolManager() {
    const { enqueueSnackbar } = useSnackbar();
    const { web3, address } = useConnectWallet();
    const { pools } = useSelector(
        state => ({
            pools: state.vault.pools
        }),
        shallowEqual,
    );
    // if(!web3) return;
    const isPoolAdded = async (contractAddress) => {
        try {
            const contract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
            return await contract.methods.getPoolId(contractAddress).call().catch(e => false);
        } catch (e) {
            return false
        }
    }
    // const contract = new web3.eth.Contract(erc20ABI, pools[0].earnContractAddress);
    // 获取合约名称和精度
    async function checkContract(contractAddress) {
        const contract = new web3.eth.Contract(erc20ABI, contractAddress);
        return Promise.all([contract.methods.name().call(), contract.methods.decimals().call()])
    }
    // 新增池子
    // add（代币合约地址, 用户收益分成万分比, 0.001 * 币种精度, 10000, true）
    async function addNewPool({ contractAddress, profit, decimals }) {
        // console.log('addNewPool',contractAddress, profit, decimals)
        const contract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
        // return await contract.methods.add(contractAddress, 10000 - profit * 100, 0.001*(Number(`1e${decimals}`)), 10000, true).send({from:address})
        // const poolId = await contract.methods.getPoolId(contractAddress).call();
        return new Promise((resolve, reject) => {
            contract.options.gas = 8600000;
            contract.methods.add(contractAddress, 10000 - profit * 100, 0.001 * (Number(`1e${decimals}`)), 10000, true).send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    // 编辑策略地址
    async function editPoolControl(contractAddress) {
        let contract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        contract = new web3.eth.Contract(definBankControlAbi, ctrAddress);
        // console.log('contractAddress', contractAddress)
        // const contract = new web3.eth.Contract(definBankControlAbi, pools[0].earnContractAddress);
        return new Promise((resolve, reject) => {
            contract.options.gas = 8600000;
            contract.methods.addStrategy(contractAddress).send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    // 获取策略的长度
    const [strategyPools, setStrategyPools] = useState([])
    const getStrategy = async () => {
        let contract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        contract = new web3.eth.Contract(definBankControlAbi, ctrAddress);
        // 获取长度
        const length = await contract.methods.strategyLength().call().catch(e => 0);
        const lengthMap = Array(+length).fill();
        // 拿合约地址
        const contractAddressList = await Promise.all(lengthMap.map((item, index) => {
            return contract.methods.strategieList(index).call();
        })).catch(e => [])

        // want() 币对信息
        // paused() 是否
        // setPause() 开启关闭
        // withdraw(代币合约地址，乘以精度后的金额 ) 解除质押
        console.log('contractAddressList', contractAddressList)
        const contractStrategyList = await Promise.all(contractAddressList.map((address) => {
            const contract = new web3.eth.Contract(StrategyAbi, address);
            return new Promise(async (resolve, reject) => {
                // 获取币对信息
                const want = await contract.methods.want().call().then((ret) => {
                    // console.log('want success',ret)
                    return ret
                }).catch(e => {
                    // console.log('want error',e)
                    return e
                });
                // 获取是否暂停
                const paused = await contract.methods.paused().call().then((ret) => {
                    // console.log('paused success',ret);
                    return ret
                }).catch(e => {
                    // console.log('pused error',e)
                    return e
                });
                resolve({ want, paused, address })
            })
        }))
        console.log('contractStrategyList', contractStrategyList)
        // 通过want的两个地址去获取名称
        for (let contractStrategy of contractStrategyList) {
            const want = contractStrategy.want;
            const contract0 = new web3.eth.Contract(erc20ABI, want['0']);
            const contract1 = new web3.eth.Contract(erc20ABI, want['1']);
            const names = await Promise.all([contract0.methods.name().call(), contract1.methods.name().call()]);

            want['00'] = names[0]
            want['11'] = names[1]
        }
        console.log('contractStrategyList', contractStrategyList)
        // 设置侧率池子
        setStrategyPools(contractStrategyList)
    }
    // 解除质押
    async function unZhiYa(strategyPool) {
        const contract = new web3.eth.Contract(StrategyAbi, strategyPool.address);
        contract.options.gas = 8600000;
        // return await contract.methods.withdrawAll().send()
        return new Promise((resolve, reject) => {
            contract.methods.withdrawAll().send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`解除质押成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
        // console.log('unZhiYa',strategyPool)
    }
    // 开启关闭自动质押
    async function closeAutoZhiYa(strategyPool) {
        const contract = new web3.eth.Contract(StrategyAbi, strategyPool.address);
        contract.options.gas = 8600000;
        // return await contract.methods.setPause().send()
        return new Promise((resolve, reject) => {
            contract.methods.setPause().send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    // alert(10)
                    enqueueSnackbar(`自动质押设置成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
        // console.log('closeAutoZhiYa',strategyPool)

    }
    // 设置配对顺序
    async function setMatchQueueCall(matchQueue) {
        if (!web3) { return }
        let contract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
        let ctrAddress = await contract.methods.controller().call().catch(e => 0)
        contract = new web3.eth.Contract(definBankControlAbi, ctrAddress);
        contract.options.gas = 8600000;
        console.log('setMatchQueueCall', matchQueue.split(','))
        return new Promise((resolve, reject) => {
            contract.methods.setStrategyList(matchQueue.split(/\s*,\s*/)).send({ from: address }).on('transactionHash', function (hash) {
                resolve(hash);
            })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`设置配对顺序成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
        // console.log('matchQueue',matchQueue)
    }
    // 资金管理-提取金额
    async function withdrawAmount(tokenAddress, amount, tokenDecimals) {
        if (!web3) { return }
        // 输入的金额
        const amountByVal = new BigNumber(amount).multipliedBy(new BigNumber(10).exponentiatedBy(tokenDecimals)).toString(10)
        if (isNaN(amountByVal)) {
            enqueueSnackbar(`请输入合法的数字`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 4000
            })
            return;
        }
        // defiBank合约
        const defiBankContract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
        const controllerAddress = await defiBankContract.methods.controller().call().catch(e => 0)
        // controller 的合约
        const controllerContract = new web3.eth.Contract(definBankControlAbi, controllerAddress);
        defiBankContract.options.gas = 8600000;
        controllerContract.options.gas = 8600000;
        // 先查余额
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);
        contract.options.gas = 8600000;
        const balanceOf = await contract.methods.balanceOf(pools[0].earnContractAddress).call().catch(e => e);

        // 如果balanceOf比输入的值小酒不用调withdraw
        if (balanceOf >= amountByVal) {
            // 再调defibank的 inCaseTokensGetStuck(代币合约地址, 金额)
            defiBankContract.methods.inCaseTokensGetStuck(tokenAddress, amountByVal).send({ from: address }).on('transactionHash', function (hash) {
                // resolve(hash);
            })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`设置成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })

                })
                .on('error', function (error) {
                    enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .catch((error) => {
                    enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                });
        } else {
            // 1.先调 controller的withdraw(代币合约地址, 金额)
            controllerContract.methods.withdraw(tokenAddress, amountByVal).send({ from: address }).on('transactionHash', function (hash) {
                // resolve(hash);
            })
                .on('receipt', function (receipt) {
                    // alert(10)
                    enqueueSnackbar(`设置成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    // 2.再调defibank的 inCaseTokensGetStuck(代币合约地址, 金额)
                    defiBankContract.methods.inCaseTokensGetStuck(tokenAddress, amountByVal).send({ from: address }).on('transactionHash', function (hash) {
                        // resolve(hash);
                    })
                        .on('receipt', function (receipt) {
                            enqueueSnackbar(`设置成功`, {
                                variant: 'success', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                autoHideDuration: 4000
                            })

                        })
                        .on('error', function (error) {
                            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                autoHideDuration: 4000
                            })
                        })
                        .catch((error) => {
                            enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                autoHideDuration: 4000
                            })
                        });
                    // resolve(receipt)
                })
                .on('error', function (error) {
                    // reject(error)
                    enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                })
                .catch((error) => {
                    // reject(error)
                    enqueueSnackbar(`错误${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                });
        }
    }
    return {
        setMatchQueueCall,
        checkContract,
        addNewPool,
        isPoolAdded,
        editPoolControl,
        getStrategy,
        strategyPools,
        unZhiYa,
        closeAutoZhiYa,
        withdrawAmount
    }
}

// 设置项目总额度
export function useSetAndGetTotals() {
    const { web3, address } = useConnectWallet();
    const dispatch = useDispatch();
    const { pools } = useSelector(
        state => ({
            pools: state.vault.pools
        }),
        shallowEqual,
    );
    // if (!web3) return;
    const getPoolTotal = async () => {
        if (!web3) { return }
        const contract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
        for (let pool of pools) {
            const { tokenDecimals } = pool;
            const poolId = await contract.methods.getPoolId(pool.tokenAddress).call();
            const { totalAmountLimit, profit } = await contract.methods.poolInfo(poolId).call();
            pool.profit = profit;
            pool.total = (totalAmountLimit / Number(`1e${tokenDecimals}`)).toFixed()
        }
        // console.log('pools', pools)
        dispatch({
            type: VAULT_FETCH_POOL_BALANCES_SUCCESS,
            data: [...pools]
        });
    }
    const intervalProfitAndTotals = () => {
        setInterval(getPoolTotal, 5000)
    }
    return {
        getPoolTotal,
        intervalProfitAndTotals
    }
}

// 总收益
export function useRateAndTotals() {
    const { enqueueSnackbar } = useSnackbar();
    const { web3, address } = useConnectWallet();
    const sendRates = (val, tokenAddress) => {
        // 设置平台分成比例 setProfit(代币地址, 10000 - 输入框的值*100, true)
        // console.log('pools[0].earnContractAddress',pools[0].earnContractAddress)
        if (!web3) { return }
        // const contract = new web3.eth.Contract(defiBankAbi, 'pools[0].earnContractAddress);
        const contract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
        contract.options.gas = 8600000;
        // console.log('10000 - val * 100, true',10000 - val * 100)
        // return 
        return new Promise((resolve, reject) => {
            contract.options.gas = 8600000;
            contract.methods.setProfit(tokenAddress, 10000 - val * 100, true).send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })

    }
    const setPoolLuoBing = () => {
        if (!web3) { return }
        const contract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
        contract.options.gas = 8600000;
        // return 
        return new Promise((resolve, reject) => {
            contract.options.gas = 8600000;
            contract.methods.setMin('0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047', 10000).send({ from: address })
                .on('transactionHash', function (hash) {
                    enqueueSnackbar(`设置:${hash}`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000,

                    })
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    enqueueSnackbar(`设置成功`, {
                        variant: 'success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    resolve(receipt)
                })
                .on('error', function (error) {
                    // enqueueSnackbar(`设置失败:${JSON.stringify(error)}`, {
                    //     variant: 'error', anchorOrigin: {
                    //         vertical: 'top',
                    //         horizontal: 'center',
                    //     },
                    //     autoHideDuration: 4000
                    // })
                    reject(error)
                })
                .catch((error) => {
                    enqueueSnackbar(`设置失败:${JSON.stringify(error)}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 4000
                    })
                    reject(error)
                })
        })
    }
    const sendTotals = async (val, tokenAddress, tokenDecimals) => {
        // 设置 投资上限 setTotalAmountLimit(代币合约地址, 不带小数金额)
        if (!web3) { return }
        const contract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
        contract.options.gas = 8600000;
        // new BigNumber(val).multipliedBy(new BigNumber(10).exponentiatedBy(tokenDecimals)).toString(10)
        // console.log(new BigNumber(val).multipliedBy(new BigNumber(10).exponentiatedBy(tokenDecimals)).toString(10))
        // return
        return new Promise((resolve, reject) => {
            contract.options.gas = 8600000;
            contract.methods.setTotalAmountLimit(tokenAddress, new BigNumber(val).multipliedBy(new BigNumber(10).exponentiatedBy(tokenDecimals)).toString(10)).send({ from: address })
                .on('transactionHash', function (hash) {
                    resolve(hash);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt)
                })
                .on('error', function (error) {
                    reject(error)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    return {
        sendRates,
        sendTotals,
        setPoolLuoBing
    }
}


// 总收益
export function useRateAndReward() {
    const { web3, address } = useConnectWallet();
    const [apl, setTvl] = useState([]);
    const fetchTvl = useCallback(async () => {
        if (!web3) { return }
        const contract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
        const apl = await contract.methods.rateAndReward().call();
        const returnTvl = apl.map(item => {
            const token = item[0];
            let tokenDecimals = 18;
            pools.forEach(pool => {
                if (pool.tokenAddress === token) {
                    tokenDecimals = pool.tokenDecimals;
                }
            })
            return [...item, tokenDecimals]
        })
        setTvl(returnTvl);
    }, [setTvl, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchTvl()
        }
        let refreshInterval = setInterval(fetchTvl, 5000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchTvl])

    return apl
}
// 总锁仓量
export function useRateAndTvl() {
    const { web3, address } = useConnectWallet();
    const [tvl, setTvl] = useState([]);

    const fetchTvl = useCallback(async () => {
        if (!web3) { return }
        const contract = new web3.eth.Contract(defiBankAbi, pools[0].earnContractAddress);
        const tvl = await contract.methods.rateAndTvl().call();
        const returnTvl = tvl.map(item => {
            const token = item[0];
            let tokenDecimals = 18;
            pools.forEach(pool => {
                if (pool.tokenAddress === token) {
                    tokenDecimals = pool.tokenDecimals;
                }
            })

            return [...item, tokenDecimals]
        })
        // console.log('returnTvl',returnTvl)
        setTvl(returnTvl);
    }, [setTvl, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchTvl()
        }
        let refreshInterval = setInterval(fetchTvl, 5000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchTvl])

    return tvl
}
// 已存入
export function useDeposited(poolAddress, tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [deposited, setDeposited] = useState("0");

    const fetchDeposited = useCallback(async () => {
        if (!web3) { return }
        const contract = new web3.eth.Contract(defiBankAbi, poolAddress);
        const deposited = await contract.methods.getDepositAsset(tokenAddress, address).call()
        setDeposited(deposited)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, setDeposited, poolAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchDeposited()
        }
        let refreshInterval = setInterval(fetchDeposited, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchDeposited])

    return deposited
}
// 收益率
export function useApy(poolAddress, tokenAddress, pool, rateMdx) {
    const { web3, address } = useConnectWallet();
    const [apy, setApy] = useState("0");

    const fetchApy = useCallback(async () => {
        if (!web3) { return }
        const prevApy = apy / (100 * rateMdx);
        const contract = new web3.eth.Contract(defiBankAbi, poolAddress);
        const newapy = await contract.methods.getApy(tokenAddress).call()
        const nextApy = newapy / (100 * rateMdx);
        //console.log(`下一次${pool.token}的Apy`,prevApy,nextApy, (nextApy - prevApy )/prevApy)
        if (nextApy < prevApy && Math.abs(nextApy - prevApy) / prevApy > 0.2) {
            return;
        }
        setApy(newapy)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, setApy, poolAddress, web3, apy])

    useEffect(() => {
        if (web3 && address) {
            fetchApy()
        }
        let refreshInterval = setInterval(fetchApy, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchApy])

    return apy
}
// 已收益
export function useEarned(poolAddress, tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [earned, setEarned] = useState("0");
    const fetchEarned = useCallback(async () => {
        if (!web3) { return }
        const contract = new web3.eth.Contract(defiBankAbi, poolAddress);
        const earned = await contract.methods.earned(tokenAddress, address).call()
        setEarned(earned)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, setEarned, poolAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchEarned()
        }
        let refreshInterval = setInterval(fetchEarned, 10000)
        return () => clearInterval(refreshInterval)
    }, [web3, address, fetchEarned])

    return earned
}

